<template>
    <swiper :options="swiperOptions"
            :auto-update="true"
            :auto-destroy="true"
            ref="swiper"
            class="taplink-swiper"
    >
        <swiper-slide v-for="item of content.slides" :key="item.id" class="slider-item">
            <div class="slider-item-wrap">
                <div class="slider-item-picture" :style="{ paddingTop: content.slideSize + '%' }">
                    <div
                            v-if="item.image"
                            class="slider-item__img"
                            :style="{ backgroundImage: `url('${item.image.url}')` }"
                    ></div>
                    <div v-else class="slider-item__empty">
                        <v-icon src="image"/>
                    </div>
                </div>
                <div class="slider-item-description" v-if="content.desc">
                    <div class="slider-item-title" v-html="item.desc.title || 'Заголовок'"></div>
                    <div class="slider-item-text" v-html="item.desc.text"></div>
                </div>
                <a class="slider-item-link" :href="setLinkUrl(item.link.url)" v-if="content.link"
                   v-html="item.link.label || 'Открыть'"></a>
            </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
</template>

<script>
    import {Swiper, SwiperSlide} from "vue-awesome-swiper";
    import "swiper/css/swiper.min.css"
    import VIcon from "./VIcon";
    import {setLinkUrl} from "../config/helpers";

    export default {
        name: "TapSlider",
        components: {
            Swiper,
            SwiperSlide,
            VIcon
        },
        props: {
            content: {}
        },
        data() {
            return {
                swiperOptions: {
                    loopedSlides: 5,
                    spaceBetween: 10,
                    watchOverflow: true,
                    slideToClickedSlide: false,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    }
                }
            }
        },
        beforeMount() {
          if (this.content.autoplay.enable) {
            this.swiperOptions.autoplay = {delay: this.content.autoplay.delay * 1000}
          }
        },
        methods: {
            setLinkUrl,
        },
    };
</script>

<style scoped lang="scss">
    .taplink-swiper {
        width: 100%;
        &.swiper-container {
            padding-bottom: 25px;
        }

        &-wrapper {
            height: 100% !important;
        }

        .swiper-pagination {
            position: absolute !important;
            bottom: 0;

            &-bullet {
                width: 10px !important;
                height: 10px !important;
                background-color: rgba(#53a3e0, 0.3) !important;

                &-active {
                    background-color: #53a3e0 !important;
                }
            }
        }
    }

    .slider-item {
        overflow: hidden;
        border-radius: 5px;
        border: 1px solid rgba(#000, 0.07);

        &-wrap {
            border-radius: 5px;
            overflow: hidden;
        }

        &-picture {
            position: relative;
            width: 100%;
            border-bottom: 1px solid rgba(#000, 0.07);
            cursor: pointer;
        }

        &__img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        &__empty {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .svg-icon {
                font-size: 100px;
            }
        }

        &-description {
            background: #fff;
            padding: 14px;
            /*border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
            border-top: none;
        }

        &-title {
            font-size: 18px;
            line-height: 1.5;
        }

        &-text {
            font-size: 14px;
            opacity: 0.7;
            white-space: pre-line;
        }

        &-link {
            display: block;
            font-size: 16px;
            padding: 17px 20px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            text-align: center;
            background: #fff;
            color: #0383de;
        }
    }
</style>
